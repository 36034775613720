import { inject } from 'vue';
import { api } from "@/providers/api";
import useAuth from '@/providers/auth';

export function useApi() {

    const { logout } = useAuth();

    const loading = inject('loading', {
        active: false,
        message: 'Cargando...'
    });

    async function make(url: string, params: any){
        loading.active = true;
        try {
            const { data } = await api.post(url, params, {
                responseType: "json"
            });
            loading.active = false;
            if(data.reauth){
                logout();
            }
            return {
              data
            };
        } catch(err){
            loading.active = false;
            return {
                data: null
            };
        }
    }

    return { loading, make };
}